import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Navbar';
import ConfirmationModal from './ConfirmationModal';

function TodoList() {
  const loadTodosFromLocalStorage = () => {
    const storedTodos = localStorage.getItem('todos');
    return storedTodos ? JSON.parse(storedTodos) : [];
  };

  const [todos, setTodos] = useState(loadTodosFromLocalStorage());
  const [darkMode, setDarkMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bibleVerse, setBibleVerse] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [todoToDelete, setTodoToDelete] = useState(null);

  const maxCharLimit = 100;

  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => {
      if (!prevDarkMode) {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
      return !prevDarkMode;
    });
  };

  const bibleVerses = [
    'Be strong and courageous. Do not be afraid; do not be discouraged, for the Lord your God will be with you wherever you go. - Joshua 1:9',
    'For I know the plans I have for you, declares the Lord, plans to prosper you and not to harm you, plans to give you hope and a future. - Jeremiah 29:11',
    'I can do all things through Christ who strengthens me. - Philippians 4:13',
    'Trust in the Lord with all your heart and lean not on your own understanding. - Proverbs 3:5',
    'Do not be anxious about anything, but in every situation, by prayer and petition, with thanksgiving, present your requests to God. - Philippians 4:6',
  ];

  useEffect(() => {
    const navItems = document.querySelectorAll("nav li");
    navItems.forEach((item) => {
      item.classList.add("fade-in");
    });
  }, []);

  useEffect(() => {
    const randomVerse = bibleVerses[Math.floor(Math.random() * bibleVerses.length)];
    setBibleVerse(randomVerse);
    setTimeout(() => setLoading(false), 2000);
  }, []);

  const saveTodosToLocalStorage = (todos) => {
    localStorage.setItem('todos', JSON.stringify(todos));
  };

  useEffect(() => {
    saveTodosToLocalStorage(todos);
  }, [todos]);

  const handleAddTodo = (text, deadline) => {
    if (text.trim() === '') {
      setErrorMessage('Please enter a to-do item.');
      return;
    }

    setTodos((prevTodos) => {
      const newTodos = prevTodos.concat({ text, deadline, completed: false });
      saveTodosToLocalStorage(newTodos);
      return newTodos;
    });

    setInputValue('');
    setCharCount(0);
    setErrorMessage('');
  };

  const handleDeleteTodo = () => {
    setTodos((prevTodos) => {
      const newTodos = prevTodos.filter((prevTodo) => prevTodo !== todoToDelete);
      saveTodosToLocalStorage(newTodos);
      return newTodos;
    });
    setShowModal(false);
  };

  const handleToggleTodo = (todo) => {
    setTodos((prevTodos) => {
      const newTodos = prevTodos.map((prevTodo) =>
        prevTodo === todo ? { ...prevTodo, completed: !prevTodo.completed } : prevTodo
      );
      saveTodosToLocalStorage(newTodos);
      return newTodos;
    });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value.length <= maxCharLimit) {
      setInputValue(value);
      setCharCount(value.length);
      setErrorMessage('');
    }
  };

  const openModal = (todo) => {
    setTodoToDelete(todo);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setTodoToDelete(null);
  };

  return (
    <>
      {loading ? (
        <div className="loading">{bibleVerse}</div>
      ) : (
        <>
          <Navbar />
          <div className="warning-box">
            Warning: Clearing your browser data or using private/incognito mode may delete your saved To-Do List tasks. For the best experience, avoid these actions to retain your settings and information.
          </div>
          <div className="dark-mode-switch">
            <button onClick={toggleDarkMode}>
              {darkMode ? "Light Mode" : "Dark Mode"}
            </button>
          </div>
          <div className={`container${darkMode ? " dark-mode" : ""}`}>
            <h1>WhatToDo?</h1>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                const textInput = event.target.elements.todo;
                const deadlineInput = event.target.elements.deadline;
                handleAddTodo(textInput.value, deadlineInput.value);
              }}
            >
              <input
                type="text"
                name="todo"
                maxLength={maxCharLimit}
                value={inputValue}
                onChange={handleInputChange}
              />
              <input type="date" name="deadline" />
              <button type="submit">Add Todo</button>
            </form>
            {charCount === maxCharLimit && (
              <p style={{ color: 'red' }}>You have reached the maximum character limit.</p>
            )}
            {errorMessage && (
              <p style={{ color: 'red' }}>{errorMessage}</p>
            )}
            <ul>
              {todos.map((todo) => (
                <li key={todo.text}>
                  <span
                    style={{
                      textDecoration: todo.completed ? "line-through" : "none",
                    }}
                  >
                    {todo.text}
                  </span>
                  <span>
                    {todo.deadline ? ` (Deadline: ${todo.deadline})` : ""}
                  </span>
                  <div>
                    <button onClick={() => handleToggleTodo(todo)}>Toggle</button>
                    <button onClick={() => openModal(todo)}>Delete</button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="social-media">
              <a href="https://discord.com/" target="_blank" rel="noreferrer">
                <i className="fab fa-discord"></i>
              </a>
              <a href="https://github.com/" target="_blank" rel="noreferrer">
                <i className="fab fa-github"></i>
              </a>
              <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
          <ConfirmationModal show={showModal} onClose={closeModal} onConfirm={handleDeleteTodo} />
        </>
      )}
    </>
  );
}

export default TodoList;
