import React, { useState } from 'react';
import './App.css';
import './App.js';

function Navbar() {
  const [activeTab, setActiveTab] = useState('todos');
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  
  return (
    <nav>
     <ul>
        <li className={activeTab === 'todos' ? 'active' : ''} onClick={() => handleTabClick('todos')}>Todos</li>
        <li className={activeTab === 'about' ? 'active' : ''} onClick={() => handleTabClick('about')}>Goals (Coming Soon)</li>
      </ul>
    </nav>
  );
}

export default Navbar;
